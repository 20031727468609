import { useEffect, useState } from 'react';
import ClickableTable from '../components/ClickableTable';
import ResultDetail from '../components/ResultDetail';
import SelectInput from '../components/SelectInput';

const ResultScreen = (props) => {
    const api = props.API;

    const [results, setResults] = useState([]);
    const [herds, setHerds] = useState([]);
    const [herdIndex, setHerdIndex] = useState(0);
    const [resultIndex, setResultIndex] = useState(0);

    const getResults = async () => {
        const r = await api.getResults();
        setResults(r);
    }

    const getHerds = async () => {
        const h = [{id: null, name: "All"}]
        const r = await api.getActiveHerds();
        const hs = h.concat(r);
        setHerds(hs);
    }

    const getTableData = () => {
        let r =  results
        .filter((val) => {
            if (herds.length > 0 && herds[herdIndex].id == null) {
                return true;
            }

            return herds.length > 0 && val.herd.id === herds[herdIndex].id;
        })
        .map((result, index) => {
            const herd = herds.find((h)=>{return h.id === result.herd.id});
            const herd_name = herd? herd.name: "";
            const num_cows = result.num_cows;
            const milkweight = result.milk_weight.toFixed(2) + ' lb';
            const butterfat = result.butterfat.toFixed(2) + ' %';
            const protein = result.protein.toFixed(2) + ' %';
            const scc = result.scc;
            const date = new Date(result.time_uploaded).toLocaleDateString();
            const url = api.getDownloadLink(result.id);
            const link = result.imported? "": <a href={url}>Download</a>;

            return [index, herd_name, date, num_cows, milkweight, butterfat, protein, scc, link];
        });
        return r;
    }

    useEffect(() => {
        getHerds();
        getResults();
    }, [])

    return (
        <div className="ResultsScreen">
            {api.render()}
            {
                results.length===0 || herds.length===0? null:
                <ResultDetail
                    result={results[resultIndex]}
                    herd={herds.find(h => h.id === results[resultIndex].herd.id)}
                    API={api}
                />
            }
            <p style={{color: 'red', position: 'relative', top: '-40px'}}>If you can't find your download, check your Downloads folder</p>
            <SelectInput
                placeholder="Filter By Herd"
                options={herds}
                selectedIndex={herdIndex}
                onSelect={setHerdIndex}
            />
            <ClickableTable
                title="Recent Uploads"
                headers={["Herd", "Uploaded", "# Cows", "Milkweight", "Butterfat", "Protein", "Somatic Cells", ""]}
                data={getTableData()}
                onClick={setResultIndex}
                sortBy={-1}
            />
        </div>
    )
}

export default ResultScreen;
